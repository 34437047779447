import { Link, useSearchParams } from 'react-router-dom';

import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';

function PageContent(props) {

  // const [searchParams, setSearchParams] = useSearchParams();
  // const page = parseInt(searchParams.get('page') || '1', 10);
  return (
      <Pagination
        page={props.page}
        count={props.total_pages}
        renderItem={(item) => (
          <PaginationItem
            component={Link}
            to={`/${item.page === 1 ? '' : `?page=${item.page}`}`}
            {...item}
          />
        )}
      />
  );
}

export default PageContent;