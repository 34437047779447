import * as React from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import PageContent from './PageContent';
import Stack from '@mui/material/Stack';
import VideoCard from './VideoCard';
import useSWRImmutable from 'swr/immutable';
import { useSearchParams } from "react-router-dom";

const fetcher = url => fetch(url).then(r => r.json())

function VideoList() {
  let [searchParams, setSearchParams] = useSearchParams();
  const page = parseInt(searchParams.get('page') || '1', 10);

  const { data, error } = useSWRImmutable(`https://api.yyp.life/files?page=${page}`, fetcher);
  
  function formatVideoDuration(durationInSeconds) {
    const hours = Math.floor(durationInSeconds / 3600);
    const minutes = Math.floor((durationInSeconds - hours * 3600) / 60);
    const seconds = Math.floor(durationInSeconds - hours * 3600 - minutes * 60);
  
    let formattedDuration = `${hours}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    if (formattedDuration.startsWith('0:')) {
      formattedDuration = formattedDuration.slice(2)
    }
    if (formattedDuration.startsWith('0')) {
      formattedDuration = formattedDuration.slice(1)
    }

    return formattedDuration;
  }

  if (error) {
    return <div>载入失败</div>
  }
  else if (!data) {
    return <div>正在载入</div>
  }
  else {
    console.log(data);
    let files = data.documents;
    return (
      <Box>
        <Grid container spacing={2}>
          {files.map((file) => {
            let duration_human = formatVideoDuration(file.duration);
            return (
              <Grid key={file.file_name} item xs={3}>
                <VideoCard
                  model_name={file.model[0].model_name}
                  poster_s_id={file.poster_s_id}
                  datetime={file.datetime}
                  duration_human={duration_human}
                  pv_thumbnail_id={file.pv_thumbnail_id}
                  pv_thumbnail_tiles={file.pv_thumbnail_tiles}
                />
              </Grid>
            );
          })}
        </Grid>
        <Stack spacing={2}>
          <PageContent total_pages={999} page={page} />
        </Stack>
      </Box>
    );
  }
}

export default VideoList;