import * as React from 'react';

import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import { useImage } from 'react-image';

function VideoCard(props) {
  // 鼠标悬停状态
  const [active, setActive] = React.useState('hidden')
  const [delayHandler, setDelayHandler] = React.useState(null)
  // 线性进度条状态
  const [progress, setProgress] = React.useState(0);
  // 预览图状态
  const [mouseTarget, setMouseTarget] = React.useState(null)
  const [positionY, setPostionY] = React.useState(0);

  const { src } = useImage({
    srcList: `https://assets.yyp.life/${props.poster_s_id}.webp`,
  })

  const handleMouseEnter = event => {
    setMouseTarget(event.target)
    let tiles_num = Math.ceil(props.pv_thumbnail_tiles * (event.clientX - event.target.offsetLeft + 1) / event.target.offsetWidth)
    setPostionY((tiles_num - 1) / (props.pv_thumbnail_tiles - 1) * 100)
    setProgress((event.clientX - event.target.offsetLeft + 1) / event.target.offsetWidth * 100)
    setDelayHandler(setTimeout(() => {
      setActive('')
    }, 1000));

  };

  const handleMouseLeave = () => {
    clearTimeout(delayHandler)
    setMouseTarget(null)
    setActive('hidden')
  };

  const handleMouseMove = event => {
    if (mouseTarget) {
      setProgress((event.clientX - mouseTarget.offsetLeft + 1) / mouseTarget.offsetWidth * 100)
      let tiles_num = Math.ceil(props.pv_thumbnail_tiles * (event.clientX - mouseTarget.offsetLeft + 1) / mouseTarget.offsetWidth)
      setPostionY((tiles_num - 1) / (props.pv_thumbnail_tiles - 1) * 100)
    };
  };

  return (
    <Box
      sx={{
        // width: "100%",
      }}
    >
      <Box
        // 视频封面
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onMouseMove={handleMouseMove}
        sx={{
          margin: 0,
          padding: 0,
          width: "100%",
          height: 0,
          position: "relative",
          backgroundImage: `url(${src})`,
          paddingBottom: "56.25%",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <Box
          // 视频时长标
          component="span"
          sx={{
            fontSize: "8px",
            fontWeight: "bold",
            right: 0,
            bottom: 0,
            color: "rgba(255, 255, 255, 1)",
            position: "absolute",
            margin: "4px",
            padding: "2px",
            backgroundColor: "black",
          }}
        >
          {props.duration_human}
        </Box>
        <LinearProgress
          variant='determinate'
          value={progress}
          sx={{
            position: 'absolute',
            left: 0,
            right: 0,
            zIndex: 100,
            visibility: active,
          }}
        />
        <Box
        // 视频预览
        style={{
          backgroundPositionY: `${positionY}%`,

        }}
        sx={{
          margin: 0,
          padding: 0,
          width: "100%",
          height: 0,
          position: "absolute",
          backgroundImage: `url(https://assets.yyp.life/${props.pv_thumbnail_id}.webp)`,
          paddingBottom: "56.25%",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          zIndex: 99,
          visibility: active,
        }}
        />
      </Box>
      <Typography
        variant="h6"
        component="div"
        sx={{
          padding: "8px 16px 0px 16px",
        }}
      >
        {props.model_name}
      </Typography>
      <Typography
        variant="subtitle1"
        component="div"
        sx={{
          padding: "0 16px 8px 16px",
          margin: 0,
        }}
      >
        {props.datetime}
      </Typography>
    </Box>
  );
}

export default VideoCard;