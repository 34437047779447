import * as React from 'react';

import { BrowserRouter, Route, Routes } from 'react-router-dom'

import Box from '@mui/material/Box';
import { RecoilRoot } from 'recoil';
import VideoList from './components/VideoList';

function App() {
  return (
    <RecoilRoot>
      <BrowserRouter>
        <Box
          sx={{
            margin: "40px 24px 0 240px",
          }}
        >
          <Routes>
            <Route path="/" element={<VideoList />} />
            {/* <VideoList /> */}
          </Routes>
        </Box>
      </BrowserRouter>
    </RecoilRoot>
  );
}

export default App;
